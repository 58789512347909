import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css/autoplay'

export default () => {
  return (
    <>
    <div>
    <h1 className='text-[#000066] font-[600] text-[40px] my-5'>our partners</h1>
    </div>
    <Swiper
      spaceBetween={50}
      slidesPerView={3}
      className='my-5 flex items-center justify-center'
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      modules={[Autoplay]}
    >
      <SwiperSlide><img className='w-[150px]' src='/images/odoo.png' /></SwiperSlide>
      <SwiperSlide><img className='w-[100px]' src='/images/aws.png' /></SwiperSlide>
      <SwiperSlide><img className='w-[100px]' src='/images/digitalocean.png' /></SwiperSlide>
      <SwiperSlide><img className='w-[50px]' src='/images/figma.png' /></SwiperSlide>
      <SwiperSlide><img className='w-[100px]' src='/images/react.png' /></SwiperSlide>
      <SwiperSlide><img className='w-[100px]' src='/images/go.svg' /></SwiperSlide>
      <SwiperSlide><img className='w-[100px]' src='/images/node.svg' /></SwiperSlide>
    </Swiper>
    </>
  );
};