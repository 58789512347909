import { Img, Text, Button } from 'components'
import Ract from 'react'

export default function ResponsiveDesign() {
  return (
    <div>
              <div className=" flex flex-col gap-2.5  justify-start mx-auto  w-[86%]">
                      <div className="flex sm:flex-col flex-row gap-[34px] items-start justify-end md:ml-[0] ml-[590px] w-[51%] md:w-full">
                        <Text
                          className="text-4xl sm:text-[32px] md:text-[34px] text-right text-white-A700 w-[85%] sm:w-full"
                          size="txtInterBold36"
                        >
                          <span className="text-white-A700 font-inter font-bold">
                            Experience the Power of{" "}
                          </span>
                          <span className="text-teal-A700 font-inter font-bold">
                            Process
                          </span>
                          <span className="text-white-A700 font-inter font-bold">
                            .
                          </span>
                        </Text>
                        <Button
                          className="flex h-[57px] items-center justify-center mb-[31px] rounded-[28px] w-[57px]"
                          color="teal_400"
                          size="sm"
                        >
                          <Img
                            className="h-[27px]"
                            src="images/img_clarityblocksgroupline.svg"
                            alt="clarityblocksgr_One"
                          />
                        </Button>
                      </div>
                      <div className="flex md:flex-col flex-row md:gap-5 items-center justify-start mr-[91px] w-[93%] md:w-full">
                        <div className="flex flex-col gap-[3px] items-end justify-start w-auto">
                          <Text
                            className="bg-clip-text bg-gradient4  text-2xl md:text-[22px] text-right text-transparent sm:text-xl w-auto"
                            size="txtInterSemiBold24"
                          >
                            Discover
                          </Text>
                          <Text
                            className="leading-[156.52%] max-w-[192px] md:max-w-full text-base text-right text-white-A700"
                            size="txtInterRegular16WhiteA700"
                          >
                            Every project starts with understanding the
                            challenge and the landscape...
                          </Text>
                        </div>
                        <div className="md:h-[200px] h-[293px] ml-5 md:ml-[0] md:mt-0 mt-[39px] relative w-[35%] md:w-full">
                          <div className=" bg-gray-900_01 border border-gray-600_19 border-solid bottom-[0] md:h-[200px] h-[271px] left-[0] p-[18px] rounded-[10px] shadow-bs4 w-[96%]">
                            <div className=" bg-blue_gray-200_19 flex flex-col h-max inset-[0] items-center justify-center m-auto p-[5px] rounded-[10px] w-[84%]">
                              <Img
                                className="h-[134px] my-7"
                                src="images/img_group38.svg"
                                alt="groupThirtyEight"
                              />
                            </div>
                          </div>
                          <Text
                            className="absolute justify-center bg-gray-900_01 border border-gray-600_19 border-solid flex h-[43px] items-center  right-[0] rounded-[21px] text-center text-white-A700 text-xl top-[0] w-[43px]"
                            size="txtInterRegular20WhiteA700"
                          >
                            1
                          </Text>
                        </div>
                        <Text
                          className="md:ml-[0] ml-[89px] text-gray-400 text-right text-xl w-[38%] sm:w-full"
                          size="txtInterRegular20"
                        >
                          Instead of focusing just on the problem, we take the
                          holistic approach so we can build a solution that
                          works end to end.{" "}
                        </Text>
                      </div>
                      <div className="flex md:flex-col flex-row gap-6 items-center justify-start w-[50%] md:w-full">
                          <div className="flex flex-col gap-1.5 items-end justify-start w-auto">
                            <Text
                              className="bg-clip-text bg-gradient4  text-2xl md:text-[22px] text-right text-transparent sm:text-xl w-auto"
                              size="txtInterSemiBold24"
                            >
                              Design
                            </Text>
                            <Text
                              className="leading-[156.52%] max-w-[192px] md:max-w-full text-base text-right text-white-A700"
                              size="txtInterRegular16WhiteA700"
                            >
                              ...allowing us to design a solution that meets
                              your needs...
                            </Text>
                          </div>
                          <div className="md:h-[226px] h-[319px] relative w-[66%] md:w-full">
                            <div className="absolute bg-gray-900_01 border border-gray-600_19 border-solid bottom-[0] md:h-[226px] h-[297px] left-[0] p-[30px] sm:px-5 rounded-[10px] shadow-bs4 w-[96%] sm:w-full">
                              <div className="absolute bg-blue_gray-200_19 h-[221px] inset-[0] justify-center m-auto rounded-[10px] w-[83%]"></div>
                              <Img
                                className="absolute h-[226px] inset-[0] justify-center m-auto object-cover w-[81%]"
                                src="images/img_image60.png"
                                alt="imageSixty"
                              />
                            </div>
                            <Text
                              className="absolute bg-gray-900_01 border border-gray-600_19 border-solid flex h-[43px] items-center justify-center right-[0] rounded-[21px] text-center text-white-A700 text-xl top-[0] w-[43px]"
                              size="txtInterRegular20WhiteA700"
                            >
                              2
                            </Text>
                          </div>
                        </div>
                      <div className=" flex md:flex-col flex-row md:gap-10 gap-[75px] items-start justify-between  mt-5">
                  <div className="flex sm:flex-col md:flex-col flex-row gap-[18px] items-center justify-start mb-[151px] w-[40%] md:mb-[0px] sm:mb-[0px] md:w-full sm:w-full">
                    <div className="flex flex-col items-end justify-start w-auto">
                      <Text
                        className="bg-clip-text bg-gradient4  text-2xl md:text-[22px] text-right text-transparent sm:text-xl w-auto"
                        size="txtInterSemiBold24"
                      >
                        Develop
                      </Text>
                      <Text
                        className="leading-[156.52%] max-w-[192px] md:max-w-full text-base text-right text-white-A700"
                        size="txtInterRegular16WhiteA700"
                      >
                        ...keeping development quality high and costs low...
                      </Text>
                    </div>
                    <div className="md:h-[238px] h-[262px] relative w-[62%] sm:w-full">
                      <div className="md:h-[238px] h-[262px] m-auto w-full">
                        <div className="absolute bg-gray-900_01 border border-gray-600_19 border-solid bottom-[0] h-[238px] left-[0] rounded-[10px] shadow-bs4 w-[93%]"></div>
                        <Button
                          className="absolute border border-gray-600_19 border-solid flex h-[43px] items-center justify-center right-[0] rounded-[21px] top-[0] w-[43px]"
                          shape="round"
                          color="gray_900_01"
                          size="sm"
                        >
                          <Img src="images/img_grid.svg" alt="grid" />
                        </Button>
                      </div>
                      <div className="absolute bg-blue_gray-200_19 bottom-[6%] flex flex-col items-center justify-start left-[4%] p-[5px] rounded-[10px] w-[86%]">
                        <Img
                          className="h-[183px] md:h-auto my-1.5 object-cover rounded-[5px] w-full"
                          src="images/img_image61.png"
                          alt="imageSixtyOne"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex sm:flex-col md:flex-col flex-row gap-[19px] items-center justify-start md:mt-0 mt-[195px] w-auto sm:w-full">
                    <div className="flex flex-col gap-px items-end justify-start w-auto">
                      <Text
                        className="bg-clip-text bg-gradient4  text-2xl md:text-[22px] text-right text-transparent sm:text-xl w-auto"
                        size="txtInterSemiBold24"
                      >
                        Deliver
                      </Text>
                      <Text
                        className="leading-[156.52%] max-w-[206px] md:max-w-full text-base text-right text-white-A700"
                        size="txtInterRegular16WhiteA700"
                      >
                        ...resulting in a tailored solution that provides you
                        value.
                      </Text>
                    </div>
                    <div
                      className="bg-cover bg-no-repeat flex flex-col h-[218px] items-start justify-end p-[50px] md:px-10 sm:px-5 w-[56%] sm:w-full"
                      style={{
                        backgroundImage: "url('images/img_group37.svg')",
                      }}
                    >
                      <Img
                        className="h-[94px] md:h-auto md:ml-[0] ml-[30px] mt-6 object-cover w-[94px]"
                        src="images/img_image63.png"
                        alt="imageSixtyThree"
                      />
                    </div>
                  </div>
                       </div>
                    </div>
    </div>
  )
}
