import React from "react";

const sizeClasses = {
  txtInterRegular16WhiteA700: "font-inter font-normal",
  txtInterRegular20: "font-inter font-normal",
  txtInterSemiBold14: "font-inter font-semibold",
  txtInterBold36: "font-bold font-inter",
  txtInterRegular20WhiteA700: "font-inter font-normal",
  txtInterBold48: "font-bold font-inter",
  txtInterSemiBold24: "font-inter font-semibold",
  txtInterBold32: "font-bold font-inter",
  txtInterSemiBold16: "font-inter font-semibold",
  txtInterBold24: "font-bold font-inter",
  txtInterRegular16Gray40099: "font-inter font-normal",
  txtInterBold40: "font-bold font-inter",
  txtInterBold64: "font-bold font-inter",
  txtInterBlack75: "font-black font-inter",
  txtInterRegular14WhiteA700: "font-inter font-normal",
  txtInterSemiBold20: "font-inter font-semibold",
  txtInterRegular8: "font-inter font-normal",
  txtInterRegular14: "font-inter font-normal",
  txtInterRegular16: "font-inter font-normal",
  txtInterRegular16WhiteA700_1: "font-inter font-normal italic",
};

const Text = ({ children, className = "", size, as, ...restProps }) => {
  const Component = as || "p";

  return (
    <Component
      className={`text-left ${className} ${size && sizeClasses[size]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Text };
