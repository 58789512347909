import React, { useRef } from "react";

import { Button, Img, Input, Line, List, Text } from "components";
import OurPartners from "pages/peartners/OurPartners";
import ResponsiveDesign from "pages/test";


const HomeDraftOnePage = () => {
  const aboutRef = useRef(null);
  const servicesRef = useRef(null);
  const contactRef = useRef(null);
  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <>
      <div className="bg-white-A700 flex flex-col font-inter items-center justify-end mx-auto w-full">
        <div className="md:h-[1371px] sm:h-[1587px] h-[995px] pb-1  relative w-full bg-black-900">
          <Img
            className="h-[991px] m-auto object-cover w-full"
            src="images/img_bg.png"
            alt="bg"
          />
          <div className="absolute flex flex-col h-max inset-[0] items-center justify-center mt-5 m-auto w-[85%]">
            <header className="flex flex-col items-center justify-center w-full">
              <div className="flex flex-row items-center justify-between w-full">
                <ul className="flex sm:flex-col flex-row gap-5 sm:hidden items-center justify-start md:ml-[0] ml-[66px] w-auto common-row-list">
                <div className="flex flex-row gap-[13px] items-center justify-between">
                    <Img
                      className="h-[70px] md:h-auto object-cover w-[70px]"
                      src="images/img_image37.png"
                      alt="imageThirtySeven"
                    />
                   
                  </div>
                  {/* <li>
                    <a
                      href="javascript:"
                      className="text-center text-sm text-white-A700"
                    >
                      <Text size="txtInterSemiBold14">Home</Text>
                    </a>
                  </li> */}
                  <li>
                    <a
                      href="javascript:"
                      className="text-center text-sm text-white-A700"
                    >
                      <Text size="txtInterSemiBold14" onClick={() => scrollToRef(aboutRef)}>About Us</Text>
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:"
                      className="text-center text-sm text-white-A700"
                    >
                      <Text size="txtInterSemiBold14" onClick={() => scrollToRef(servicesRef)}>Services</Text>
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="javascript:"
                      className="text-center text-sm text-white-A700"
                    >
                      <Text size="txtInterSemiBold14">Blog</Text>
                    </a>
                  </li> */}
                  <li>
                    <a
                      href="javascript:"
                      className="text-center text-sm text-white-A700"
                    >
                      <Text size="txtInterSemiBold14" onClick={() => scrollToRef(contactRef)}>Contact</Text>
                    </a>
                  </li>
                </ul>
                <Button
                  className="cursor-pointer font-bold sm:hidden leading-[normal] min-w-[145px] ml-80 md:ml-[0] md:mt-0 my-[5px] text-base text-center"
                  shape="round"
                  onClick={() => scrollToRef(contactRef)}
                >
                  Let’s Talk
                </Button>
              </div>
            </header>
            <div className="h-[182px] md:h-[308px] mt-[126px] relative w-[90%] md:w-full">
              <Text
                className="m-auto md:text-5xl text-[50px] text-center text-white-A700"
                size="txtInterBlack75"
              >
                <>
                  Byte Innovate! Solutions.
                  <br />
                  Your guide in the <span>digital age.</span> 
                </>
              </Text>
            </div>
            <Button
              className="cursor-pointer font-bold leading-[normal] min-w-[145px] mt-[34px] text-base text-center"
              shape="round"
              onClick={() => scrollToRef(contactRef)}
            >
              Let’s Talk
            </Button>
            <List
              className="sm:flex-col flex-row gap-12 grid sm:grid-cols-1 md:grid-cols-2 grid-cols-3 justify-start mt-[77px] w-auto md:w-full"
              orientation="horizontal"
            >
              <div className="flex flex-col gap-[50px] items-center justify-start w-auto">
                <div className="bg-gradient  border border-solid border-white-A700 flex flex-col h-[102px] items-center justify-start p-[17px] rounded-[50%] shadow-bs w-[102px]">
                  <Img
                    className="h-[68px] md:h-auto object-cover w-[89%]"
                    src="images/img_image52.png"
                    alt="imageFiftyTwo"
                  />
                </div>
                <div className="bg-gradient1  flex flex-col items-center justify-end p-[41px] md:px-10 sm:px-5 rounded-[15px] shadow-bs3 w-full">
                  <Text
                    className="text-base text-center text-gray-400 w-[70%] sm:w-full"
                    size="txtInterRegular16"
                  >
                    Bring together people, process, and technology
                  </Text>
                </div>
              </div>
              <div className="flex flex-col gap-[50px] items-center justify-start w-auto">
                <div className="flex flex-col h-[102px] items-center justify-start w-[102px]">
                  <div className="bg-gradient  border border-solid border-white-A700 flex flex-col h-[102px] items-center justify-start p-[21px] sm:px-5 rounded-[50%] shadow-bs w-[102px]">
                    <Img
                      className="h-[60px] md:h-auto object-cover w-[60px]"
                      src="images/img_image51.png"
                      alt="imageFiftyOne"
                    />
                  </div>
                </div>
                <div className="bg-gradient1  flex flex-col items-center justify-end p-[41px] md:px-10 sm:px-5 rounded-[15px] shadow-bs3 w-full">
                  <Text
                    className="text-base text-center text-gray-400 w-[74%] sm:w-full"
                    size="txtInterRegular16"
                  >
                    Custom solutions designed to meet your needs
                  </Text>
                </div>
              </div>
              <div className="flex flex-col gap-[50px] items-center justify-start w-auto responsive">
                <div className="flex flex-col h-[102px] items-center justify-start w-[102px]">
                  <div className="bg-gradient  border border-solid border-white-A700 flex flex-col h-[102px] items-center justify-start p-5 rounded-[50%] shadow-bs w-[102px]">
                    <Img
                      className="h-[60px] md:h-auto object-cover w-[60px]"
                      src="images/img_ux1.png"
                      alt="ux1"
                    />
                  </div>
                </div>
                <div className="bg-gradient1  flex flex-col items-center justify-end p-[41px] md:px-10 sm:px-5 rounded-[15px] shadow-bs3 w-full">
                  <Text
                    className="text-base text-center text-white-A700 w-[69%] sm:w-full"
                    size="txtInterRegular16WhiteA700"
                  >
                    Software built with the user’s experience in mind
                  </Text>
                </div>
              </div>
            </List>
            {/* <Text
              className="mt-[97px] text-base text-center text-gray-400"
              size="txtInterRegular16"
            >
              Scroll to learn more
            </Text>
            <Img
              className="h-[27px] mt-[9px] w-[27px]"
              src="images/img_arrowdown.svg"
              alt="arrowdown"
            /> */}
          </div>
        </div>
        <div className="bg-black-900 flex flex-col items-center justify-start py-1 w-full">
          <div className="bg-black-900 flex flex-col items-center justify-start p-5 w-full">
            <div className="flex flex-col items-start justify-start max-w-[1201px] mb-[113px] mx-auto md:px-5 w-full">
              <div className="flex sm:flex-col flex-row gap-4 items-center justify-between w-full">
                <Text
                  className="text-base text-center text-gray-400 tracking-[1.60px]"
                  size="txtInterRegular16"
                >
                  <span className="text-deep_purple-A200_01 font-inter font-normal">
                    01 |
                  </span>
                  <span className="text-gray-400 font-inter font-normal">
                    {" "}
                  </span>
                  <span className="text-white-A700 font-inter font-normal" ref={aboutRef}>
                    WHO WE ARE
                  </span>
                </Text>
                <Line className="bg-gradient2  h-px w-[86%]" />
              </div>
              <div className="flex md:flex-col flex-row gap-7 items-start justify-start mt-[38px] w-[62%] md:w-full">
                <Button
                  className="flex h-[57px] items-center justify-center mb-[119px] rounded-[28px] w-[57px]"
                  color="purple_900"
                  size="sm"
                >
                  <Img
                    className="h-[27px]"
                    src="images/img_clarityblocksgroupline.svg"
                    alt="clarityblocksgr"
                  />
                </Button>
                <Text
                  className="text-4xl sm:text-[32px] md:text-[34px] text-white-A700 w-[89%] sm:w-full"
                  size="txtInterBold36"
                >
                  <span className="text-white-A700 font-inter text-left font-bold">
                    We are a{" "}
                  </span>
                  <span className="text-indigo-A700 font-inter text-left font-bold">
                    group of experts
                  </span>
                  <span className="text-white-A700 font-inter text-left font-bold">
                    {" "}
                    looking to help companies leverage technology to do more
                    than they had ever thought possible.
                  </span>
                </Text>
              </div>
              <Text
                className="md:ml-[0] ml-[85px] mt-[38px] text-gray-400 text-xl w-[54%] sm:w-full"
                size="txtInterRegular20"
              >
                We do this through a number of services to provide comprehensive
                solutions for your business. Scroll down to read about what we
                can do for you.
              </Text>
              <div className="flex md:flex-col flex-row md:gap-10 items-start justify-between mt-[76px] w-full">
                <div
                  className="bg-cover bg-no-repeat flex md:flex-1 flex-col h-[375px] items-center justify-start p-8 sm:px-5 rounded-[10px] w-[46%] md:w-full"
                  style={{ backgroundImage: "url('images/img_group15.png')" }}
                >
                  <Img
                    className="h-[299px] md:h-auto mb-[7px] mt-[3px] object-cover rounded-[10px] w-full"
                    src="images/img_rectangle7.png"
                    alt="rectangleSeven"
                  />
                </div>
                <div className="flex md:flex-1 flex-col gap-[50px] items-center justify-start md:mt-0 mt-[119px] w-[49%] md:w-full">
                  <div className="flex sm:flex-col flex-row gap-3 items-start justify-start w-full">
                    <div className="sm:hidden md:hidden flex sm:flex-1 flex-col gap-3.5 items-center justify-start sm:mt-0 mt-0.5 w-[4%] sm:w-full">
                      <Img
                        className="h-5"
                        src="images/img_checkmark.svg"
                        alt="checkmark"
                      />
                      <Img
                        className="h-5"
                        src="images/img_checkmark.svg"
                        alt="checkmark_One"
                      />
                      <Img
                        className="h-5"
                        src="images/img_checkmark.svg"
                        alt="checkmark_Two"
                      />
                    </div>
                    <Text
                      className="text-gray-400 text-xl"
                      size="txtInterRegular20"
                    >
                      <>
                        We always focus on technical excellence
                        <br />
                        Wherever you’re going, we bring ideas and excitement
                        <br />
                        We’re consultants, guides, and partners for brands
                      </>
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" h-[1220px] md:px-5 relative w-full">
            <div className="absolute bg-gray-900 md:h-[1000px] sm:h-[1300px] h-[900px] inset-[0] justify-center m-auto w-full"></div>
            <div className="absolute   md:h-[1000px] sm:h-[1300px] h-[900px] inset-[0] justify-center m-auto w-full" ref={servicesRef}>
              <Img
                className="h-[806px] m-auto object-cover w-full"
                src="images/img_image42.png"
                alt="imageFortyTwo"
              />
              <div className="absolute top-[5%] flex flex-col inset-x-[0] items-center justify-start mx-auto w-[72%]">
                <Text
                  className="text-base text-center text-gray-400 tracking-[1.60px]"
                  size="txtInterRegular16"
                >
                  <span className="text-deep_purple-A200_01 font-inter font-normal">
                    02 |
                  </span>
                  <span className="text-gray-400 font-inter font-normal">
                    {" "}
                    ALL OUR{" "}
                  </span>
                  <span className="text-white-A700 font-inter font-normal">
                    SERVICES
                  </span>
                </Text>
                <Text
                  className="mt-[31px] text-4xl sm:text-[32px] md:text-[34px] text-center text-white-A700 w-[56%] sm:w-full"
                  size="txtInterBold36"
                >
                  <span className="text-white-A700 font-inter font-bold">
                    Our
                  </span>
                  <span className="text-purple-600 font-inter font-bold">
                    {" "}
                  </span>
                  <span className="text-pink-A400 font-inter font-bold">
                    comprehensive services
                  </span>
                  <span className="text-white-A700 font-inter font-bold">
                    {" "}
                    will cover all your needs.
                  </span>
                </Text>
                <Text
                  className="mt-8 text-base text-center text-gray-400 w-[64%] sm:w-full"
                  size="txtInterRegular16"
                >
                  <>
                    We don&#39;t just help with your IT needs. We focus on the
                    entire ecosystem surrounding it. Bringing together people,
                    process, and technology, we pride ourselves on delivering
                    solutions that work in reality. Not theory.
                  </>
                </Text>
                <div className="md:gap-5 gap-[45px] grid sm:grid-cols-1 md:grid-cols-2 grid-cols-3 justify-center min-h-[auto] mt-[30px] w-full">
                  <div className="flex flex-1 flex-col gap-[5px] items-center justify-center w-full">
                    <Img
                      className="h-[50px] md:h-auto object-cover w-[50px]"
                      src="images/img_group20.png"
                      alt="groupTwenty"
                    />
                    <Text
                      className="bg-clip-text bg-gradient3  text-transparent text-xl w-[289px]"
                      size="txtInterSemiBold20"
                    >
                      Information Systems Strategy
                    </Text>
                  </div>
                  <div className="flex flex-1 flex-col gap-[5px] items-center justify-center w-full">
                    <Img
                      className="h-[50px] md:h-auto object-cover w-[50px]"
                      src="images/img_entypocode.png"
                      alt="entypocode"
                    />
                    <Text
                      className="bg-clip-text bg-gradient3  text-transparent text-xl w-[168px] text-center"
                      size="txtInterSemiBold20"
                    >
                      Custom Software
                    </Text>
                  </div>
                  <div className="flex flex-1 flex-col gap-[5px] items-center justify-center w-full">
                    <Img
                      className="h-[50px] md:h-auto object-cover w-[50px]"
                      src="images/img_tablerplugconnected.png"
                      alt="tablerplugconne"
                    />
                    <Text
                      className="bg-clip-text bg-gradient3  text-transparent text-xl w-[195px] text-center"
                      size="txtInterSemiBold20"
                    >
                      Integration Services
                    </Text>
                  </div>
                  <div className="flex flex-1 flex-col gap-[5px] w-[50px] items-center justify-center m-auto">
                    <Img
                      className=" md:h-auto object-cover w-[50px] sm:w-full"
                      src="images/img_uiwmobile.png"
                      alt="uiwmobile"
                    />
                    <Text
                      className="bg-clip-text bg-gradient3  text-transparent text-xl w-[312px] text-center"
                      size="txtInterSemiBold20"
                    >
                      Web, Ecommerce & Mobile Apps
                    </Text>
                  </div>
                  <div className="flex flex-1 flex-col gap-[5px] items-center justify-center w-full">
                    <Img
                      className="h-[50px] md:h-auto object-cover w-[50px]"
                      src="images/img_akariconsgear.png"
                      alt="akariconsgear"
                    />
                    <Text
                      className="bg-clip-text bg-gradient3  text-transparent text-xl w-[205px] text-center"
                      size="txtInterSemiBold20"
                    >
                      Business Automation
                    </Text>
                  </div>
                  <div className="flex flex-1 flex-col gap-[5px] items-center justify-center w-full">
                    <Img
                      className="h-[50px] md:h-auto object-cover w-[50px]"
                      src="images/img_heroiconsoutlinelightbulb.png"
                      alt="heroiconsoutlin"
                    />
                    <Text
                      className="bg-clip-text bg-gradient3  text-transparent text-xl w-[95px] text-center"
                      size="txtInterSemiBold20"
                    >
                      Creativity
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:h-[4000px] sm:h-[4000px] h-[2102px] md:px-5 relative w-full">
            <div className="absolute md:h-[1804px] h-[2007px] inset-x-[0] mx-auto pb-[203px] top[0] md:top-[550px] sm:top-[550px] w-full">
              <Text
                className="ml-auto mr-[130px] mt-[165px] text-base text-center text-gray-400 tracking-[1.60px]"
                size="txtInterRegular16"
              >
                <span className="text-teal-A400 font-inter font-normal">
                  03 |
                </span>
                <span className="text-gray-400 font-inter font-normal"> </span>
                <span className="text-gray-400 font-inter font-normal">
                  OUR{" "}
                </span>
                <span className="text-gray-400 font-inter font-normal">
                  PROCESS
                </span>
              </Text>
              <div className="absolute h-[1804px] inset-x-[0] mx-auto top-[0] w-full">
                <div className="h-[1804px] m-auto w-full">
                  <Img
                    className="lg:h-[1804px] sm:h-[6000px] md:h-[6000px] m-auto object-cover w-full"
                    src="images/img_image41.png"
                    alt="imageFortyOne"
                  />
                  <div className="absolute h-[823px] inset-x-[0] mx-auto top-[7%] w-[97%] md:w-full">
                    <div className="h-[823px] m-auto w-full">
                      <Img
                        className="h-[823px] m-auto object-cover w-full"
                        src="images/img_image65.png"
                        alt="imageSixtyFive"
                      />
                      <div className="absolute flex flex-col md:gap-10 gap-[412px] h-max inset-y-[0] items-end justify-start left-[2%] my-auto w-[89%]">
                        <div className="flex md:flex-col flex-row gap-[30px] items-center justify-between mr-1 w-full">
                          <div className="h-px relative w-[84%] md:w-full">
                            <Line className="bg-gradient2  h-px m-auto rotate-[-180deg] w-full" />
                            <Line className="absolute bg-gradient2  h-px inset-[0] justify-center m-auto rotate-[-180deg] w-full" />
                          </div>
                          <Text
                            className="text-base text-center text-gray-400 tracking-[1.60px]"
                            size="txtInterRegular16"
                          >
                            <span className="text-teal-A400 font-inter font-normal">
                              03 |
                            </span>
                            <span className="text-gray-400 font-inter font-normal">
                              {" "}
                            </span>
                            <span className="text-gray-400 font-inter font-normal">
                              OUR{" "}
                            </span>
                            <span className="text-gray-400 font-inter font-normal">
                              PROCESS
                            </span>
                          </Text>
                        </div>
                        <div className="hidden sm:block md:block">
                        <ResponsiveDesign />
                        </div>
                        <div className="sm:hidden md:hidden flex md:flex-col flex-row gap-6 items-center justify-start w-[50%] md:w-full">
                          <div className="flex flex-col gap-1.5 items-end justify-start w-auto">
                            <Text
                              className="bg-clip-text bg-gradient4  text-2xl md:text-[22px] text-right text-transparent sm:text-xl w-auto"
                              size="txtInterSemiBold24"
                            >
                              Design
                            </Text>
                            <Text
                              className="leading-[156.52%] max-w-[192px] md:max-w-full text-base text-right text-white-A700"
                              size="txtInterRegular16WhiteA700"
                            >
                              ...allowing us to design a solution that meets
                              your needs...
                            </Text>
                          </div>
                          <div className="md:h-[226px] h-[319px] relative w-[66%] md:w-full">
                            <div className="absolute bg-gray-900_01 border border-gray-600_19 border-solid bottom-[0] md:h-[226px] h-[297px] left-[0] p-[30px] sm:px-5 rounded-[10px] shadow-bs4 w-[96%] sm:w-full">
                              <div className="absolute bg-blue_gray-200_19 h-[221px] inset-[0] justify-center m-auto rounded-[10px] w-[83%]"></div>
                              <Img
                                className="absolute h-[226px] inset-[0] justify-center m-auto object-cover w-[81%]"
                                src="images/img_image60.png"
                                alt="imageSixty"
                              />
                            </div>
                            <Text
                              className="absolute bg-gray-900_01 border border-gray-600_19 border-solid flex h-[43px] items-center justify-center right-[0] rounded-[21px] text-center text-white-A700 text-xl top-[0] w-[43px]"
                              size="txtInterRegular20WhiteA700"
                            >
                              2
                            </Text>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sm:hidden md:hidden absolute flex flex-col gap-2.5 inset-x-[0] justify-start mx-auto  top-[13%] sm:bottom-[70%] md:bottom-[70%] w-[86%]">
                      <div className="flex sm:flex-col flex-row gap-[34px] items-start justify-end  w-[51%] md:w-full m-auto">
                        <Text
                          className="text-4xl sm:text-[32px] md:text-[34px] text-right text-white-A700 w-[85%] sm:w-full"
                          size="txtInterBold36"
                        >
                          <span className="text-white-A700 font-inter font-bold">
                            Experience the Power of{" "}
                          </span>
                          <span className="text-teal-A700 font-inter font-bold">
                            Process
                          </span>
                          <span className="text-white-A700 font-inter font-bold">
                            .
                          </span>
                        </Text>
                        <Button
                          className="flex h-[57px] items-center justify-center mb-[31px] rounded-[28px] w-[57px]"
                          color="teal_400"
                          size="sm"
                        >
                          <Img
                            className="h-[27px]"
                            src="images/img_clarityblocksgroupline.svg"
                            alt="clarityblocksgr_One"
                          />
                        </Button>
                      </div>
                      <div className="flex md:flex-col flex-row md:gap-5 items-center justify-start mr-[91px] w-[93%] md:w-full">
                        <div className="flex flex-col gap-[3px] items-end justify-start w-auto">
                          <Text
                            className="bg-clip-text bg-gradient4  text-2xl md:text-[22px] text-right text-transparent sm:text-xl w-auto"
                            size="txtInterSemiBold24"
                          >
                            Discover
                          </Text>
                          <Text
                            className="leading-[156.52%] max-w-[192px] md:max-w-full text-base text-right text-white-A700"
                            size="txtInterRegular16WhiteA700"
                          >
                            Every project starts with understanding the
                            challenge and the landscape...
                          </Text>
                        </div>
                        <div className="md:h-[200px] h-[293px] ml-5 md:ml-[0] md:mt-0 mt-[39px] relative w-[35%] md:w-full">
                          <div className="absolute bg-gray-900_01 border border-gray-600_19 border-solid bottom-[0] md:h-[200px] h-[271px] left-[0] p-[18px] rounded-[10px] shadow-bs4 w-[96%]">
                            <div className="absolute bg-blue_gray-200_19 flex flex-col h-max inset-[0] items-center justify-center m-auto p-[5px] rounded-[10px] w-[84%]">
                              <Img
                                className="h-[134px] my-7"
                                src="images/img_group38.svg"
                                alt="groupThirtyEight"
                              />
                            </div>
                          </div>
                          <Text
                            className="absolute justify-center bg-gray-900_01 border border-gray-600_19 border-solid flex h-[43px] items-center  right-[0] rounded-[21px] text-center text-white-A700 text-xl top-[0] w-[43px]"
                            size="txtInterRegular20WhiteA700"
                          >
                            1
                          </Text>
                        </div>
                        <Text
                          className="md:ml-[0] ml-[89px] text-gray-400 text-right text-xl w-[38%] sm:w-full"
                          size="txtInterRegular20"
                        >
                          Instead of focusing just on the problem, we take the
                          holistic approach so we can build a solution that
                          works end to end.{" "}
                        </Text>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sm:hidden md:hidden absolute bottom-[29%] flex md:flex-col flex-row md:gap-10 gap-[75px] items-start justify-between left-[9%] w-[78%]">
                  <div className="flex sm:flex-col flex-row gap-[18px] items-center justify-start mb-[151px] w-[40%] md:mb-[0px] sm:mb-[0px] md:w-full sm:w-full">
                    <div className="flex flex-col items-end justify-start w-auto">
                      <Text
                        className="bg-clip-text bg-gradient4  text-2xl md:text-[22px] text-right text-transparent sm:text-xl w-auto"
                        size="txtInterSemiBold24"
                      >
                        Develop
                      </Text>
                      <Text
                        className="leading-[156.52%] max-w-[192px] md:max-w-full text-base text-right text-white-A700"
                        size="txtInterRegular16WhiteA700"
                      >
                        ...keeping development quality high and costs low...
                      </Text>
                    </div>
                    <div className="md:h-[238px] h-[262px] relative w-[62%] sm:w-full">
                      <div className="md:h-[238px] h-[262px] m-auto w-full">
                        <div className="absolute bg-gray-900_01 border border-gray-600_19 border-solid bottom-[0] h-[238px] left-[0] rounded-[10px] shadow-bs4 w-[93%]"></div>
                        <Button
                          className="absolute border border-gray-600_19 border-solid flex h-[43px] items-center justify-center right-[0] rounded-[21px] top-[0] w-[43px]"
                          shape="round"
                          color="gray_900_01"
                          size="sm"
                        >
                          <Img src="images/img_grid.svg" alt="grid" />
                        </Button>
                      </div>
                      <div className="absolute bg-blue_gray-200_19 bottom-[6%] flex flex-col items-center justify-start left-[4%] p-[5px] rounded-[10px] w-[86%]">
                        <Img
                          className="h-[183px] md:h-auto my-1.5 object-cover rounded-[5px] w-full"
                          src="images/img_image61.png"
                          alt="imageSixtyOne"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex sm:flex-col flex-row gap-[19px] items-center justify-start md:mt-0 mt-[195px] w-auto sm:w-full">
                    <div className="flex flex-col gap-px items-end justify-start w-auto">
                      <Text
                        className="bg-clip-text bg-gradient4  text-2xl md:text-[22px] text-right text-transparent sm:text-xl w-auto"
                        size="txtInterSemiBold24"
                      >
                        Deliver
                      </Text>
                      <Text
                        className="leading-[156.52%] max-w-[206px] md:max-w-full text-base text-right text-white-A700"
                        size="txtInterRegular16WhiteA700"
                      >
                        ...resulting in a tailored solution that provides you
                        value.
                      </Text>
                    </div>
                    <div
                      className="bg-cover bg-no-repeat flex flex-col h-[218px] items-start justify-end p-[50px] md:px-10 sm:px-5 w-[56%] sm:w-full"
                      style={{
                        backgroundImage: "url('images/img_group37.svg')",
                      }}
                    >
                      <Img
                        className="h-[94px] md:h-auto md:ml-[0] ml-[30px] mt-6 object-cover w-[94px]"
                        src="images/img_image63.png"
                        alt="imageSixtyThree"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute bottom-[0] flex flex-col inset-x-[0] items-center justify-start mx-auto md:px-10 sm:px-5 px-[65px] w-full">
              <div className="flex flex-col justify-start w-[96%] md:w-full">
                <div className="flex md:flex-col flex-row gap-[34px] items-center justify-between md:ml-[0] ml-[17px] w-[99%] md:w-full">
                  <Text
                    className="text-base text-center text-gray-400 tracking-[1.60px]"
                    size="txtInterRegular16"
                  >
                    <span className="text-pink-400 font-inter font-normal">
                      04 |
                    </span>
                    <span className="text-gray-400 font-inter font-normal">
                      {" "}
                    </span>
                    <span className="text-gray-400 font-inter font-normal">
                      TESTIMONIALS
                    </span>
                  </Text>
                  <Line className="bg-gradient2  h-px w-[83%]" />
                </div>
                <div className="flex md:flex-col flex-row gap-[35px] items-start justify-start mt-[53px] w-3/5 md:w-full">
                  <Button
                    className="flex h-[57px] items-center justify-center mb-[31px] rounded-[28px] w-[57px]"
                    color="pink_400"
                    size="sm"
                  >
                    <Img
                      className="h-[27px]"
                      src="images/img_clarityblocksgroupline.svg"
                      alt="clarityblocksgr_Two"
                    />
                  </Button>
                  <Text
                    className="text-4xl sm:text-[32px] md:text-[34px] text-white-A700"
                    size="txtInterBold36"
                  >
                    <span className="text-white-A700 font-inter text-left font-bold">
                      <>
                        Testimonials.
                        <br />
                      </>
                    </span>
                    <span className="text-pink-400 font-inter text-left font-bold">
                      Loved{" "}
                    </span>
                    <span className="text-white-A700 font-inter text-left font-bold">
                      by product people.
                    </span>
                  </Text>
                </div>
                <Text
                  className="ml-24 md:ml-[0] mt-[21px] text-gray-400 text-xl w-[52%] sm:w-full"
                  size="txtInterRegular20"
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam.
                </Text>
                <List
                  className="sm:flex-col flex-row gap-[21px] grid sm:grid-cols-1 md:grid-cols-2 grid-cols-3 md:ml-[0] ml-[42px] mt-[134px] w-[90%]"
                  orientation="horizontal"
                >
                  <div className="h-[365px] relative w-full">
                    <Img
                      className="h-[103px] mb-[-45px] mx-auto rounded-[50%] w-[103px] z-[1]"
                      src="images/img_ellipse2.png"
                      alt="ellipseTwo"
                    />
                    <div className="bg-gray-800 flex flex-col items-center justify-end mt-auto mx-auto p-[13px] rounded-[10px] w-full">
                      <div className="flex flex-col gap-[30px] items-center justify-start mt-[69px] w-auto">
                        <Img
                          className="h-[42px] md:h-auto object-cover w-[49px]"
                          src="images/img_image44.png"
                          alt="imageFortyFour"
                        />
                        <Text
                          className="max-w-[306px] md:max-w-full text-2xl md:text-[22px] text-center text-white-A700 sm:text-xl"
                          size="txtInterBold24"
                        >
                          <>
                            I couldn&#39;t be any happier. Highly recommended!
                          </>
                        </Text>
                        <Text
                          className="text-center text-gray-400 text-xl"
                          size="txtInterRegular20"
                        >
                          <span className="text-gray-400 font-inter font-bold">
                            <>
                              Marcus W.
                              <br />
                            </>
                          </span>
                          <span className="text-gray-400 font-inter text-base font-normal">
                            Developer
                          </span>
                        </Text>
                      </div>
                    </div>
                  </div>
                  <div className="h-[365px] relative w-full">
                    <Img
                      className="h-[103px] mb-[-45px] mx-auto rounded-[50%] w-[103px] z-[1]"
                      src="images/img_ellipse2_103x103.png"
                      alt="ellipseTwo"
                    />
                    <div className="bg-gray-800 flex flex-col items-center justify-end mt-auto mx-auto p-[13px] rounded-[10px] w-full">
                      <div className="flex flex-col gap-[30px] items-center justify-start mt-[69px] w-auto">
                        <Img
                          className="h-[42px] md:h-auto object-cover w-[49px]"
                          src="images/img_image44.png"
                          alt="imageFortyFour"
                        />
                        <Text
                          className="max-w-[306px] md:max-w-full text-2xl md:text-[22px] text-center text-white-A700 sm:text-xl"
                          size="txtInterBold24"
                        >
                          Great service and custom service! Perfect!
                        </Text>
                        <Text
                          className="text-center text-gray-400 text-xl"
                          size="txtInterRegular20"
                        >
                          <span className="text-gray-400 font-inter font-bold">
                            <>
                              Jessy K.
                              <br />
                            </>
                          </span>
                          <span className="text-gray-400 font-inter text-base font-normal">
                            Front-End Developer
                          </span>
                        </Text>
                      </div>
                    </div>
                  </div>
                  <div className="h-[365px] relative w-full">
                    <Img
                      className="h-[103px] mb-[-45px] mx-auto rounded-[50%] w-[103px] z-[1]"
                      src="images/img_ellipse2_1.png"
                      alt="ellipseTwo"
                    />
                    <div className="bg-gray-800 flex flex-col items-center justify-end mt-auto mx-auto p-[13px] rounded-[10px] w-full">
                      <div className="flex flex-col gap-[30px] items-center justify-start mt-[69px] w-auto">
                        <Img
                          className="h-[42px] md:h-auto object-cover w-[49px] "
                          src="images/img_image44.png"
                          alt="imageFortyFour"
                        />
                        <Text
                          className="text-2xl md:text-[22px] text-center text-white-A700 sm:text-xl"
                          size="txtInterBold24"
                        >
                          <>
                            Perfect. Just perfect!
                            <br />I can’t say anything.
                          </>
                        </Text>
                        <Text
                          className="text-center text-gray-400 text-xl"
                          size="txtInterRegular20"
                        >
                          <span className="text-gray-400 font-inter font-bold">
                            <>
                              Marina J.
                              <br />
                            </>
                          </span>
                          <span className="text-gray-400 font-inter text-base font-normal">
                            HR Officer
                          </span>
                        </Text>
                      </div>
                    </div>
                  </div>
                </List>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center justify-start p-[103px] md:px-10 sm:px-5 w-full">
            <div className="flex flex-col items-center justify-start max-w-[1150px] mb-[7px] mx-auto w-full">
              <div className="flex flex-col gap-[49px] items-start justify-start w-full" ref={contactRef}>
                <Text
                  className="md:text-5xl text-[64px] text-white-A700"
                  size="txtInterBold64"
                >
                  <span className="text-white-A700 font-inter text-left font-bold">
                    Get
                  </span>
                  <span className="text-white-A700 font-inter text-left font-bold">
                    {" "}
                    in Touch with our{" "}
                  </span>
                  <span className="text-blue-A700 font-inter text-left font-bold">
                    Team
                  </span>
                  <span className="text-white-A700 font-inter text-left font-bold">
                    .
                  </span>
                </Text>
                <div className="flex md:flex-col flex-row md:gap-[47px] items-center justify-between ml-0.5 md:ml-[0] w-full">
                  <div className="flex md:flex-1 flex-col items-start justify-start w-[48%] md:w-full z-[500]">
                    <Input
                      name="groupTwelve"
                      placeholder="Full Name"
                      className="leading-[normal] p-0 placeholder:text-gray-400_99 text-base text-left w-full"
                      wrapClassName="flex w-full"
                      type="text"
                      prefix={
                        <Img
                          className="h-[30px] mr-[17px] my-auto"
                          src="images/img_bxsusercircle.svg"
                          alt="bxs:user-circle"
                        />
                      }
                      shape="round"
                      color="gray_900_02"
                      size="sm"
                      variant="fill"
                    ></Input>
                    <Input
                      name="groupEleven"
                      placeholder="Email Address"
                      className="leading-[normal] p-0 placeholder:text-gray-400_99 text-base text-left w-full"
                      wrapClassName="flex mt-[13px] w-full"
                      type="email"
                      prefix={
                        <Img
                          className="h-[30px] mr-5 my-auto"
                          src="images/img_fluentmail20filled.svg"
                          alt="fluent:mail-20-filled"
                        />
                      }
                      shape="round"
                      color="gray_900_02"
                      size="sm"
                      variant="fill"
                    ></Input>
                    <Input
                      name="groupTen"
                      placeholder="Phone Number"
                      className="leading-[normal] p-0 placeholder:text-gray-400_99 text-base text-left w-full"
                      wrapClassName="flex mt-[13px] w-full"
                      type="text"
                      prefix={
                        <Img
                          className="h-[30px] mr-[17px] my-auto"
                          src="images/img_antdesignphonefilled.svg"
                          alt="ant-design:phone-filled"
                        />
                      }
                      shape="round"
                      color="gray_900_02"
                      size="sm"
                      variant="fill"
                    ></Input>
                    <div className="bg-gray-900_02 flex flex-row gap-5 items-start justify-start mt-[13px] p-[17px] rounded-[10px] w-full">
                      <Img
                        className="h-[30px] mb-[137px] w-[30px]"
                        src="images/img_bxschat.svg"
                        alt="bxschat"
                      />
                      <Input
                      name="groupTen"
                      placeholder="write something..."
                      className="leading-[normal] p-0 placeholder:text-gray-400_99 text-base text-left w-full pt-0"
                      wrapClassName="flex mt-[13px] w-full"
                      type="text"
                      shape="round"
                      color="gray_900_02"
                      size="sm"
                      variant="fill"
                    ></Input>
                      {/* <Text
                        className="mb-[140px] mt-1.5 text-base text-gray-400_99"
                        size="txtInterRegular16Gray40099"
                      >
                        Write something here...
                      </Text> */}
                    </div>
                    <Button
                      className="cursor-pointer font-bold leading-[normal] min-w-[167px] mt-[21px] text-base text-center"
                      shape="round"
                    >
                      Submit Now
                    </Button>
                  </div>
                  <div className="h-[538px] relative w-[49%] md:w-full">
                    <Img
                      className="h-[538px] m-auto object-cover rounded-[10px] w-full"
                      src="images/img_image48.png"
                      alt="imageFortyEight"
                    />
                    <div className="absolute bottom-[9%] flex flex-col inset-x-[0] items-center justify-start mx-auto w-[83%]">
                      <Text
                        className="md:text-3xl sm:text-[28px] text-[32px] text-center text-white-A700 w-full"
                        size="txtInterBold32"
                      >
                        “We specialise in helping our customers digitise their
                        business”
                      </Text>
                      <Img
                        className="h-[103px] md:h-auto mt-[58px] rounded-[50%] w-[103px]"
                        src="images/img_ellipse2_103x103.png"
                        alt="ellipseTwo"
                      />
                      <Text
                        className="mt-[13px] text-center text-gray-400 text-xl"
                        size="txtInterRegular20"
                      >
                        <span className="text-gray-400 font-inter font-bold">
                          <>
                            Sandra Lewis
                            <br />
                          </>
                        </span>
                        <span className="text-gray-400 font-inter text-base font-normal">
                          Front-End Developer
                        </span>
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <OurPartners />
          <div className="md:h-[1400px] h-[708px]  md:px-5 relative w-full">
            <footer className="absolute bottom-[0] flex inset-x-[0] items-center justify-center mx-auto w-full">
              <div className="bg-gray-900_03 flex flex-col gap-[45px] items-center justify-center pt-[235px] w-full">
                <div className="flex md:flex-col flex-row gap-7 items-start justify-start w-auto md:w-full">
                  <div className="flex flex-col gap-[25px] items-start justify-start w-auto">
                    <div className="flex flex-col gap-[15px] items-start justify-start w-auto">
                      <Text
                        className="text-base text-white-A700 w-auto"
                        size="txtInterSemiBold16"
                      >
                        COMPANY INFO
                      </Text>
                      <Text
                        className="max-w-[266px] md:max-w-full text-gray-400 text-sm"
                        size="txtInterRegular14"
                      >
                        Reach out to us anytime and lets create a better future
                        for all technology users together, forever. We are open
                        to all types of collab offers and tons more.
                      </Text>
                    </div>
                    <div className="flex flex-col gap-4 items-end justify-start w-auto">
                      <div className="flex flex-row gap-[11px] items-center justify-start w-auto">
                        <Img
                          className="h-4 w-4"
                          src="images/img_clock.svg"
                          alt="clock"
                        />
                        <Text
                          className="text-gray-400 text-sm"
                          size="txtInterRegular14"
                        >
                          <>
                            Office Hours <br />
                            Monday-Friday 9 AM-5 PM
                          </>
                        </Text>
                      </div>
                      
                    </div>
                    <div className="flex flex-col gap-4 items-end justify-start w-auto">
                      <div className="flex flex-row gap-[11px] items-center justify-start w-auto">
                        <Img
                          className="h-4 w-4"
                          src="images/img_antdesignphonefilled.svg"
                          alt="phone"
                        />
                        <Text
                          className="text-gray-400 text-sm"
                          size="txtInterRegular14"
                        >
                          <a href="tel:+201092574755" className="cursor-pointer">
                            Call us at <br />
                            +201092574755
                          </a>
                        </Text>
                      </div>
                      
                    </div>
                  </div>
                  {/* <div className="flex flex-col gap-[15px] items-start justify-start w-auto">
                    <Text
                      className="text-base text-white-A700 w-auto"
                      size="txtInterSemiBold16"
                    >
                      CONTACT INFO
                    </Text>
                    <Input
                      name="email"
                      placeholder="info@techyessolutions.com"
                      className="leading-[normal] p-0 placeholder:text-white-A700 text-left text-sm text-white-A700 w-full"
                      wrapClassName="flex w-full"
                      type="email"
                      prefix={
                        <Img
                          className="mt-auto mb-px h-5 mr-3"
                          src="images/img_evaemailfill.svg"
                          alt="eva:email-fill"
                        />
                      }
                    ></Input>
                    <div className="flex flex-row gap-2.5 items-start justify-start w-auto">
                      <Img
                        className="h-5 w-5"
                        src="images/img_carbonphonefilled.svg"
                        alt="carbonphonefill"
                      />
                      <Text
                        className="text-sm text-white-A700 w-auto"
                        size="txtInterRegular14WhiteA700"
                      >
                        858-227-4878
                      </Text>
                    </div>
                  </div> */}
                  <div className="flex flex-col gap-[15px] items-start justify-start w-auto">
                    <Text
                      className="text-base text-white-A700 w-auto"
                      size="txtInterSemiBold16"
                    >
                      OUR SERVICES
                    </Text>
                    <ul className="flex flex-col items-start justify-start md:w-full common-column-list">
                      <li>
                        <a href="javascript:" className="text-gray-400 text-sm">
                          <Text size="txtInterRegular14">
                            Information Systems Strategy
                          </Text>
                        </a>
                      </li>
                      <li>
                        <a
                          href="javascript:"
                          className="mt-[13px] text-gray-400 text-sm"
                        >
                          <Text size="txtInterRegular14">Custom Software</Text>
                        </a>
                      </li>
                      <li>
                        <a
                          href="javascript:"
                          className="mt-4 text-gray-400 text-sm"
                        >
                          <Text size="txtInterRegular14">
                            Integration Services
                          </Text>
                        </a>
                      </li>
                      <li>
                        <a
                          href="javascript:"
                          className="mt-[13px] text-gray-400 text-sm"
                        >
                          <Text size="txtInterRegular14">
                            Business Automation
                          </Text>
                        </a>
                      </li>
                      <li>
                        <a
                          href="javascript:"
                          className="mt-4 text-gray-400 text-sm"
                        >
                          <Text size="txtInterRegular14">
                            Web, Ecommerce & Mobiel Apps
                          </Text>
                        </a>
                      </li>
                      <li>
                        <a
                          href="javascript:"
                          className="mt-[13px] text-gray-400 text-sm"
                        >
                          <Text size="txtInterRegular14">Creative</Text>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="flex flex-col gap-3.5 items-start justify-start w-auto">
                    <Text
                      className="text-base text-white-A700 w-auto"
                      size="txtInterSemiBold16"
                    >
                      OUR LOCATIONS
                    </Text>
                    <Img
                      className="h-[178px] sm:h-auto object-cover w-[314px] md:w-full"
                      src="images/img_techyesmap013.png"
                      alt="techyesmap013"
                    />
                  </div>
                </div>
                <div className="bg-gray-900_04 flex flex-col items-center justify-start p-[15px] w-full">
                  <Text
                    className="mb-1.5 text-center text-gray-400 text-sm"
                    size="txtInterRegular14"
                  >
                    © 2024 Byte - Innovate! Solutions. All Rights Reserved
                  </Text>
                </div>
              </div>
            </footer>
            <div className="absolute h-[331px] inset-x-[0] mx-auto md:px-10 sm:px-5 px-[230px] top-[0] w-full">
              <div className="bg-gradient  h-[331px] m-auto rounded-[10px]"></div>
              <div className="absolute flex flex-col gap-[27px] h-max inset-[0] justify-center m-auto w-[51%]">
                <div className="flex flex-col gap-[9px] justify-start w-full">
                  <div className="flex flex-col items-center justify-start w-full">
                    <div className="flex md:flex-col flex-row gap-5 items-center justify-start sm:justify-center md:justify-center w-auto md:w-full">
                      <Text
                        className="sm:text-[20px] md:text-[30px] text-[35px] text-center text-white-A700 w-auto"
                        size="txtInterBold48"
                      >
                        Move even faster with
                      </Text>
                      <div className="flex flex-row gap-[7px] items-center w-[26%] md:w-full sm:justify-center md:justify-center">
                        <Img
                          className="h-[70px] md:h-auto object-cover w-[70px]"
                          src="images/img_image37.png"
                          alt="maskgroup_One"
                        />
                      </div>
                    </div>
                  </div>
                  <Text
                    className="sm:text-[20px] md:text-[38px] text-[40px] text-center text-white-A700"
                    size="txtInterBold40"
                  >
                    Your guide in the digital age.
                  </Text>
                </div>
                <div className="flex sm:flex-col flex-row gap-[13px] items-center justify-center  w-auto sm:w-full">
                  <Button
                    className="cursor-pointer font-bold leading-[normal] min-w-[145px] text-base text-center"
                    shape="round"
                    color="white_A700"
                    onClick={() => scrollToRef(contactRef)}
                  >
                    Let’s Talk
                  </Button>
                  <Button
                    className="cursor-pointer font-bold leading-[normal] min-w-[256px] text-base text-center"
                    shape="round"
                    color="deep_purple_A700"
                    onClick={() => scrollToRef(contactRef)}
                  >
                    Book Your Consultation
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fixed bottom-[5%] right-[5%] w-[60px]">
        <a href="https://wa.me/201092574755?text="  target="_blank" className="cursor-pointer">
        <Img
        className="cursor-pointer"
        src="images/whatsapp.png"
        alt="whatsapp"
        />
        </a>
        </div>
      </div>
    </>
  );
};

export default HomeDraftOnePage;
