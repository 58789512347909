import React from "react";
import { Link } from "react-router-dom";
import HomeDraftOnePage from "./HomeDraftOne";

const Home = () => {
  return (
    <div>
     <HomeDraftOnePage />
    </div>
  );
};
export default Home;
